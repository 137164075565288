import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import PropTypes from "prop-types"
import * as React from "react"
import { useEffect } from "react"
import langEn from "../images/lang-en.png"
import langTr from "../images/lang-tr.png"
import logo from "../images/markn-concept-logo.png"
import "./header.css"
export default function Header({ siteTitle }) {
  const { t } = useTranslation()
  const { changeLanguage, language } = useI18next()
  useEffect(() => {
    var homepage = ["/"]
    if (homepage.indexOf(window.location.pathname) >= 0) {
      document.querySelector("header").classList.add("index")
    }
  }, [])
  function mobileMenu() {
    document.getElementById("nav").classList.toggle("mobile-nav")
    document.getElementById("mobile-menu").classList.toggle("is-active")
  }
  const isBrowser = () => typeof window !== "undefined"
  function addScrollClass(header) {
    header.classList.add("scrolled")
  }
  function removeScrollClass(header) {
    header.classList.remove("scrolled")
  }
  isBrowser() &&
    window.addEventListener("scroll", function () {
      const scrollPos = window.scrollY
      const header = document.querySelector("#header")
      if (scrollPos > 10) {
        addScrollClass(header)
      } else {
        removeScrollClass(header)
      }
    })
  return (
    <header
      className="absolute top-12 w-full header-bg h-32 flex items-center justify-center md:top-0 md:h-16 z-50"
      id="header"
    >
      <div className="w-9/12 h-full flex items-center justify-between md:w-10/12">
        <Link to="/">
          <img
            src={logo}
            alt=""
            className="transition duration-300 md:max-h-12"
          />
        </Link>
        <div
          role={"button"}
          tabIndex={0}
          className="hidden md:block menu-toggle justify-self-end"
          id="mobile-menu"
          onClick={mobileMenu}
          onKeyDown={mobileMenu}
        >
          <span className="h-1 mx-auto my-1 transition duration-300 rounded-md bar w-7 md:block bg-white"></span>
          <span className="h-1 mx-auto my-1 transition duration-300 rounded-md bar w-7 md:block bg-white"></span>
          <span className="h-1 mx-auto my-1 transition duration-300 rounded-md bar w-7 md:block bg-white"></span>
        </div>
        <ul
          className="flex items-center justify-end w-4/5 p-0 m-0 transition duration-300 md:bg-white md:left-0 md:top-16 md:absolute md:w-screen md:h-screen md:flex-col md:-translate-x-full md:justify-start md:pt-5"
          id="nav"
        >
          <li className="transition duration-300 m-5">
            <Link
              to="/"
              className="text-base transition duration-300 text-white font-first hover:hover-bold w-full flex justify-center md:text-xl"
              onClick={mobileMenu}
            >
              {t("Anasayfa")}
            </Link>
          </li>
          <li className="transition duration-300 m-5">
            <Link
              to="/biz-kimiz"
              className="text-base transition duration-300 text-white font-first hover:hover-bold w-full flex justify-center md:text-xl"
              onClick={mobileMenu}
            >
              {t("Hakkimizda")}
            </Link>
          </li>
          <li className="transition duration-300 m-5">
            <Link
              to="/urunler"
              className="text-base transition duration-300 text-white font-first hover:hover-bold w-full flex justify-center md:text-xl"
              onClick={mobileMenu}
            >
              {t("Urunler")}
            </Link>
          </li>
          <li className="transition duration-300 m-5">
            <Link
              to="/referanslar"
              className="text-base transition duration-300 text-white font-first hover:hover-bold w-full flex justify-center md:text-xl"
              onClick={mobileMenu}
            >
              {t("Referanslar")}
            </Link>
          </li>
          <li className="transition duration-300 m-5 mr-0 md:mr-5">
            <Link
              to="/iletisim"
              className="pr-0 text-base text-white font-first sm:px-6 hover:hover-bold w-full flex justify-center md:text-xl"
              onClick={mobileMenu}
            >
              {t("Iletisim")}
            </Link>
          </li>
          <li className="transition duration-300 m-5 mr-0 md:mr-5">
            <button
              onClick={() => changeLanguage(language === "tr" ? "en" : "tr")}
              className="pr-0 text-base text-white font-first sm:px-6 hover:hover-bold w-full flex justify-center md:text-xl"
            >
              <img src={language === "en" ? langTr : langEn} alt="" />
            </button>
          </li>
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
