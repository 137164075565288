import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "dayjs/locale/tr"
import "dayjs/locale/en"
import dayjs from "dayjs"
import { useI18next, useTranslation, Link } from "gatsby-plugin-react-i18next"
import "./layout.css"
import whiteLogo from '../images/logo-white.png'
import facebook from '../images/facebook.svg'
import linkedin from '../images/linkedin.svg'
import instagram from '../images/instagram.svg'
import postLogo from '../images/post-kreatif-imza.png'

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)

  if (language === "en") {
    dayjs.locale("en")
  } else {
    dayjs.locale("tr")
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          width: '100%',
        }}
      >
        <main>{children}</main>
        <footer className="mt-5 flex flex-col">
          {/* © {new Date().getFullYear()}, Markn Concept */}
          <div className="w-full footer-bg h-28 flex items-center md:h-fit">
            <div className="w-10/12 mx-auto py-3">
              <div className="flex items-center justify-between md:flex-col">
                <div className="flex items-stretch md:flex-col">
                  <img src={whiteLogo} alt="markn logo" className="md:w-6/12 md:mx-auto md:mb-3" />
                  <div className="flex flex-wrap ml-5 pl-5 border-l-2 border-white items-center md:border-none md:justify-center md:ml-0 md:flex-col md:pl-0">
                    <Link to="/" className="font-first text-white font-semibold text-base mr-5 md:mr-0 md:text-sm md:mb-2">{t('Anasayfa')}</Link>
                    <Link to="/biz-kimiz" className="font-first text-white font-semibold text-base mr-5 md:mr-0 md:text-sm md:mb-2">{t('Hakkimizda')}</Link>
                    <Link to="/urunler" className="font-first text-white font-semibold text-base mr-5 md:mr-0 md:text-sm md:mb-2">{t('Urunler')}</Link>
                    <Link to="/referanslar" className="font-first text-white font-semibold text-base mr-5 md:mr-0 md:text-sm md:mb-2">{t('Referanslar')}</Link>
                    <Link to="/iletisim" className="font-first text-white font-semibold text-base mr-5 md:mr-0 md:text-sm md:mb-2">{t('Iletisim')}</Link>
                    <div className="w-full text-left font-first text-xs text-white md:mb-3">© {new Date().getFullYear()}, Markn Concept. All rights reserved.</div>
                  </div>
                </div>
                <div className="flex">
                  <a href="https://www.facebook.com/Markn-Concept-100154432603316/" target={"_blank"} rel="noreferrer" role={"button"} className="m-1"><img src={facebook} alt="facebook logo" /></a>
                  <a href="https://www.instagram.com/marknconcept" target={"_blank"} rel="noreferrer" role={"button"} className="m-1"><img src={instagram} alt="instagram logo" /></a>
                  <a href="https://www.linkedin.com/company/markn-concept/" target={"_blank"} rel="noreferrer" role={"button"} className="m-1"><img src={linkedin} alt="linkedin logo" /></a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-10/12 mx-auto flex items-center h-12 md:h-7 md:justify-center justify-end">
            <a href="https://postkreatif.com" target={"_blank"} rel="noreferrer" className="flex items-center">
              <img src={postLogo} alt="post kreatif logo" className="mr-1" />
              <div className="font-first text-default-dark text-xs font-medium md:mt-1">{t("designedBy")}</div>
            </a>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
